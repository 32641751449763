import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '',
        name: 'Index',
        component: () => import('../components/Index.vue'),
      },
      {
        path: '/product/asr-recognizer',
        name: 'Recognizer',
        component: () => import('../components/asr/Recognizer.vue'),
      },
      {
        path: '/product/asr-transcriber',
        name: 'Transcriber',
        component: () => import('../components/asr/Transcriber.vue'),
      },
      {
        path: '/product/asr-file',
        name: 'File',
        component: () => import('../components/asr/File.vue'),
      },
      {
        path: '/product/asr-file-se',
        name: 'FileSE',
        component: () => import('../components/asr/FileSE.vue'),
      },
      {
        path: '/product/asr-phone',
        name: 'Phone',
        component: () => import('../components/asr/Phone.vue'),
      },
      {
        path: '/product/asr-offline',
        name: 'Offline',
        component: () => import('../components/asr/Offline.vue'),
      },
      {
        path: '/product/tts-online',
        name: 'Online',
        component: () => import('../components/tts/Online.vue'),
      },
      {
        path: '/product/tts-customization',
        name: 'Customization',
        component: () => import('../components/tts/Customization.vue'),
      },
      {
        path: '/product/tts-offline',
        name: 'Offline',
        component: () => import('../components/tts/Offline.vue'),
      },
      {
        path: '/product/diting',
        name: 'Model',
        component: () => import('../components/diting/Diting.vue'),
      },
      {
        path: '/product/awake',
        name: 'Awake',
        component: () => import('../components/awake/Awake.vue'),
      },
      {
        path: '/product/nmt-offline',
        name: 'Machine',
        component: () => import('../components/machine/Offline.vue'),
      },
      {
        path: '/product/nmt-online',
        name: 'MachineOnline',
        component: () => import('../components/machine/Online.vue'),
      },
      {
        path: '/docs',
        name: 'Doc',
        component: () => import('../components/Doc.vue'),
      },
      {
        path: '/help',
        name: 'Help',
        component: () => import('../components/Help.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.name !== 'Help') {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
  }
})

export default router
