import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/reset.css'
import './assets/css/common.css'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import '@/assets/js/rem'
import axios from 'axios'
import SparkMD5 from 'spark-md5'
import "github-markdown-css";
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.prototype.$Axios = axios
Vue.prototype.$SparkMD5 = SparkMD5
import language from './assets/json/copywriting.json'
Vue.prototype.$language = language['zh-CN']
Vue.prototype.$Msg = language['zh-msg']
// cry
import CryptoJS from "crypto-js"
Vue.prototype.$CryptoJS = CryptoJS
Vue.use(ElementUI)
Vue.use(mavonEditor)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
